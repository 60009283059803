<template>
  <vue-final-modal class="modal-register-cancel">
    <div class="vfm__head">
      <div class="vfm__control vfm__control-left" />

      <div class="vfm__title">
        {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'title') }}
      </div>

      <div class="vfm__control vfm__control-right">
        <atomic-icon id="close" class="vfm__close" @click="closeAll" />
      </div>
    </div>

    <div class="vfm__body">
      <div class="modal-register-cancel__description" v-html="description" />

      <atomic-promo show-timer :content="bonusBannerContent" />

      <div class="modal-register-cancel__actions">
        <button-base type="primary" size="md" @click="toSignUpPage">
          {{ getContent(props.currentLocaleData, props.defaultLocaleData, 'closeButton') }}
        </button-base>
      </div>
    </div>
  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import type { CIModalsContent, CISignUpPage } from '~/types';
  import DOMPurify from 'isomorphic-dompurify';
  import { marked } from 'marked';

  const props = defineProps<{
    currentLocaleData: Maybe<CIModalsContent['cancelRegistration']>;
    defaultLocaleData: Maybe<CIModalsContent['cancelRegistration']>;
  }>();

  const contentParams = {
    contentKey: `pages-sign-up`,
    contentRoute: ['sign-up'],
  };

  const { getContentData } = useContentLogic<CISignUpPage>(contentParams);
  const { data: pageContent } = await useLazyAsyncData(getContentData);

  const { closeAllModals } = useModalStore();
  const { getContent, goBack } = useProjectMethods();

  const bonusBannerContent = computed(() =>
    getContent(pageContent.value?.currentLocaleData, pageContent.value?.defaultLocaleData, 'bonusBanner')
  );

  const description = computed(() =>
    DOMPurify.sanitize(
      marked.parseInline(getContent(props.currentLocaleData, props.defaultLocaleData, 'description') || '') as string,
      { FORBID_TAGS: ['style'] }
    )
  );

  const toSignUpPage = async () => {
    await closeAllModals();
  };

  const closeAll = async () => {
    await closeAllModals();
    await nextTick();
    await goBack();
  };
</script>

<style src="~/assets/styles/components/modal/sign-up-cancel.scss" lang="scss" />
